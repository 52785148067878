
<script>

export default {
methods:{

  // 去搜素的按钮方法，去商品列表页面
    goSearch(searchKey){
          // 再保存搜索关键字到本地
          localStorage.setItem('searchKey',searchKey)   

                // 跳转到goodslist页面即可，不做查询
this.$router.push('/GoodsList');
              // }
            
                //如果在goodsList本页面中，强制刷新本页面
                  // window.location.reload();
                  
  },
  
},

}

</script>


<template>
    <div class="box">

    
 
<ul class="navsscebox">
   
    <!-- <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('大学生创意')">大学生创意</a>
     </li> -->
     <li>
         <!-- <router-link to="https://www.sscf.top/park" class="mya">大学生创意园</router-link> -->
         <a href="https://www.sscf.top/park"  class="mya">大学生创意园</a>
         <!-- <a href="http://localhost/park"  class="mya">大学生创意园</a> -->
     </li>

     <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('AI智能化')">AI智能化</a>
     </li>

     <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('脑机接口')">脑机接口</a>
     </li>
     
     <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('智慧农业')">智慧农业</a>
     </li>
     

     <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('VR/AR')">VR/AR</a>
     </li>

     <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('3D打印')">3D技术</a>
     </li>

     <!-- <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('智能机器人')">智能机器人</a>
     </li> -->

     <!-- <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('BIM')">建筑BIM</a>
     </li> -->

     <!-- <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('园艺软装')">园艺软装</a>
     </li> -->

     <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('智慧家居')">智慧家居</a>
     </li>

     <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('物联天下')">物联天下</a>
     </li>

     <!-- <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('工业互联')">工业互联</a>
     </li> -->

    

     <!-- <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('非遗文化')">非遗文化</a>
     </li> -->

     <li>
         <!-- <router-link to="https://www.sscf.top/gk" class="mya">大学生创意园</router-link> -->
         <a href="https://www.sscf.top/gk"  class="mya">高考志愿模拟</a>
         <!-- <a href="http://localhost/gk"  class="mya">高考志愿模拟</a> -->
     </li>

    

     <li>
         <!-- <router-link to="https://www.sscf.top/car" class="mya">车联天下</router-link> -->
         <a href="https://www.sscf.top/car"  class="mya">智慧汽车</a>

         <!-- <a href="http://localhost/car"  class="mya">车联天下</a> -->
     </li>

     <!-- <li> -->
         <!-- <router-link to="https://www.sscf.top/house" class="mya">洋房定制</router-link> -->
        <!-- <a href="https://www.sscf.top//house"  class="mya">洋房定制</a> -->
         <!-- <a href="http://localhost/house"  class="mya">洋房定制</a> -->
         
     <!-- </li> -->

     <!-- <li>
         <a  href="javascript:;"   class="mya" 
         @click="goSearch('旧书')">旧书</a>
     </li> -->

     
     <!-- <li> -->
         <!-- <router-link to="http://47.109.45.55/gaokaovip" class="mya">高考志愿</router-link> -->
         <!-- <a href="http://47.109.45.55/gaokao"  class="mya">高考志愿模拟</a> -->
     <!-- </li> -->

     <li>
         <router-link to="/Abouteme" class="mya">联系我们</router-link>
     </li>
     
 </ul>

</div>

</template>
<style  lang="less" scoped>
.box{
    width: 100%;
    background: #f54343;

}
.navsscebox {
    display: flex;
    justify-content: space-around;
    width: 90%;
    height: 36px;
    margin: 0  auto;
    
    
    color: #ffffff;
    line-height: 36px;
    text-align: center;
    font-family: "Microsoft YaHei";
    margin-bottom: 10px;
    li .mya {
    color: #fff;
}



}

.navsscebox>li .mya:hover {
    border-bottom: 3px solid #ff3333;
}

.navsscebox li .logo {
    font-size: 22px;
    font-weight: 700;
    line-height: 36px;
    font-style: italic;
    
    color: #FFFFFF;
}
</style>





